import { useAppSelector } from 'customer/store/configureStore';
import React, { createContext, useContext } from 'react';
import rest from 'services/rest';

const LOGGING_INTERVAL = 5000;

interface LogPayload {
  message: string;
  severity?: 'info' | 'warning' | 'error';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: Record<string, any>;
}

interface analyticsPayload {
  event: string;
  meta?: Record<string, string>;
}

const LoggerContext = createContext<
  | {
      logMessage: (payload: LogPayload) => void;
      logAnalyticsEvent: (payload: analyticsPayload) => void;
    }
  | undefined
>(undefined);

const logCache: Record<string, number> = {};

export const LoggerProvider = ({ children }: { children: React.ReactNode }) => {
  const { userId } = useAppSelector(({ iam }) => ({
    userId: iam?.login?.auth?.userId || '',
  }));

  const logMessage = async (payload: LogPayload) => {
    // Deduplication prevents identical log messages from being sent repeatedly
    // within a short interval, reducing noise and unnecessary server requests.
    const cacheKey = `${payload.severity || 'error'}:${payload.message}`;
    const now = Date.now();
    if (logCache[cacheKey] && now - logCache[cacheKey] < LOGGING_INTERVAL) {
      return;
    }

    logCache[cacheKey] = now;

    const finalPayload: LogPayload = {
      severity: payload.severity || 'error',
      message: payload.message,
      meta: {
        userId: userId,
        ...payload.meta,
      },
    };

    try {
      await rest.post(`/log`, finalPayload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('logging failed', error);
    } finally {
      if (finalPayload.severity === 'error') {
        console.error(finalPayload.message, finalPayload.meta);
      }
    }
  };

  const logAnalyticsEvent = (payload: analyticsPayload) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', payload.event, payload.meta);
    } else {
      console.warn('Google Analytics is not initialized');
    }
  };

  return (
    <LoggerContext.Provider value={{ logMessage, logAnalyticsEvent }}>
      {children}
    </LoggerContext.Provider>
  );
};

export const useLogger = () => {
  const context = useContext(LoggerContext);
  if (!context) {
    throw new Error('useLogger must be used within a LoggerProvider');
  }
  return context;
};
