import chains from 'components/Chain/chains';
import { Chain } from 'viem';
import {
  arbitrum as arbitrumMainnet,
  arbitrumSepolia as arbitrumSepoliaTestnet,
  mainnet as ethereumMainnet,
  sepolia as ethereumSepolia,
  gnosisChiado as gnosisChiadoTestnet,
  gnosis as gnosisMainnet,
  linea as lineaMainnet,
  lineaSepolia as lineaSepoliaTestnet,
  polygonAmoy as polygonAmoyTestnet,
  polygon as polygonMainnet,
} from 'viem/chains';
import { ViemChains } from './types';

const ethereumLocalChain = chains.find((chain) => chain.id === 'ethereumlocal');

export const ethereumLocal = {
  id: ethereumLocalChain?.chainId,
  name: ethereumLocalChain?.label,
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: {
      http: [ethereumLocalChain?.explorerUrl],
    },
    public: {
      http: [ethereumLocalChain?.explorerUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
  },
  testnet: true,
  iconUrl: '/assets/emoney/chains/ethereum-icon-sm.svg',
};

export const sepolia = {
  ...ethereumSepolia,
  name: chains.find((chain) => chain.id === 'sepolia')?.label,
};

export const amoy = {
  ...polygonAmoyTestnet,
  iconUrl: '/assets/emoney/chains/polygon-icon-sm.svg',
};

export const chiado = {
  ...gnosisChiadoTestnet,
  iconUrl: '/assets/emoney/chains/gnosis-icon-sm.svg',
};

export const arbitrumSepolia = arbitrumSepoliaTestnet;
export const lineaSepolia = lineaSepoliaTestnet;

export const gnosis = gnosisMainnet;

export const arbitrum = arbitrumMainnet;
export const ethereum = ethereumMainnet;
export const polygon = polygonMainnet;
export const linea = lineaMainnet;

export type ChainEvm = Chain;

export const localhostEvmChains = [ethereumLocal];

export default [
  amoy,
  arbitrum,
  arbitrumSepolia,
  chiado,
  ethereum,
  gnosis,
  polygon,
  sepolia,
  linea,
  lineaSepolia,
  ...localhostEvmChains,
] as ViemChains;
