'use client';

import { useChains } from 'components/Chain/hooks';
import { useSharedAppContext } from 'providers/AppConfig';
import React, { useMemo } from 'react';
import { WagmiProvider, cookieToInitialState } from 'wagmi';
import evmChains from './chains';
import { config as getWagmiConfig } from './config';
import { ViemChains } from './types';

const EvmProvider = ({ children }: { children: React.ReactNode }) => {
  const { environment, cookies } = useSharedAppContext();
  const chains = useChains('evm');

  const wagmiChains = useMemo(() => {
    return evmChains.filter((evmChain) =>
      chains.some((chain) => chain.chainId === evmChain.id),
    ) as unknown as ViemChains;
  }, [chains]);

  const wagmiConfig = useMemo(() => {
    return getWagmiConfig(environment, wagmiChains);
  }, [environment, wagmiChains]);

  const wagmiInitialState = useMemo(() => {
    return cookieToInitialState(wagmiConfig, cookies);
  }, [wagmiConfig, cookies]);

  return (
    <WagmiProvider
      config={wagmiConfig}
      initialState={wagmiInitialState}
      reconnectOnMount
    >
      {children}
    </WagmiProvider>
  );
};
export default EvmProvider;
