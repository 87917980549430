import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ChainsProvider } from 'components/Chain/Provider';
import StyleContext from 'isomorphic-style-loader-react18/StyleContext';
import { SnackbarProvider } from 'notistack';
import { AppContext } from 'providers/AppConfig';
import ReactQueryProvider from 'providers/QueryProvider';
import React, { FC, useMemo } from 'react';
import { LoggerProvider } from 'services/logger';
import { SharedAppRouteConfig } from 'types/app';
import TokenProvider from './components/emoney/Token/Provider';
import WalletConnector from './components/emoney/Wallet/WalletConnector';
import { AppConfig } from './types';

const App: FC<
  {
    config: AppConfig;
    insertCss: (...styles: IsomorphicStyleLoaderStyle[]) => void;
    children: React.ReactNode;
  } & SharedAppRouteConfig
> = ({ pathname, query, config, insertCss, children }) => {
  const appCtx = useMemo(
    () => ({ pathname, query, insertCss, ...config }),
    [pathname, query, config],
  );
  const styleCtx = useMemo(() => ({ insertCss }), [insertCss]);

  return (
    <StyleContext.Provider value={styleCtx}>
      <AppContext.Provider value={appCtx}>
        <SnackbarProvider maxSnack={3}>
          <LoggerProvider>
            <ReactQueryProvider>
              <TokenProvider>
                <ChainsProvider>
                  <WalletConnector>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      {children}
                    </LocalizationProvider>
                  </WalletConnector>
                </ChainsProvider>
              </TokenProvider>
            </ReactQueryProvider>
          </LoggerProvider>
        </SnackbarProvider>
      </AppContext.Provider>
    </StyleContext.Provider>
  );
};

export default App;
